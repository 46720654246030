import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import {
  AnimatedContainer,
  BasicPaymentOptions,
  ContainedBox,
  Detail,
  ExtensionsDetailsBox,
  ExtensionsWrapper,
  ExtentionDetailsSubTitle,
  PricingExentsionWrapper,
  PayementOption,
  PayementOptionContainer,
  PaymentDetails,
  PriceWrraper,
  TextSection,
  PricingSectionWrapper,
  ExtensionSectionWrapper,
  SliderWrapperStyle,
  AnimatedBoxSliderWrapper
} from "./newPricing.style";
import CheckMarkBlue from "../../assets/icons/paymentPlanIcons/CheckMarkBasic.svg";
import CheckMarkGreen from "../../assets/icons/paymentPlanIcons/CheckMarkGreen.svg";
import CheckMarkGold from "../../assets/icons/paymentPlanIcons/CheckMarkGold.svg";
import CheckMarkRed from "../../assets/icons/paymentPlanIcons/CheckMarkRed.svg";
import ExtensionCheckMark from "../../assets/icons/paymentPlanIcons/CheckMark.svg";
import theme from "../../theme/app/themeStyles";
import { motion } from "framer-motion";
import Slider from "react-slick";

const ICONHEIGHT = 20;
const ICONWIDTH = 19;

const BasicPlan = () => {
  const icon = (
    <img
      height={ICONHEIGHT}
      width={ICONWIDTH}
      src={CheckMarkBlue}
      color="red"
    />
  );

  return (
    <PayementOption color={theme.payementPlans.basic}>
      <h1>Basique</h1>
      <PaymentDetails hoverShadowColor={theme.payementPlans.basic}>
        <Detail>
          {icon}
          <div className="text">
            un accès exclusif au store eProgram, offrant une variété de produits
            et services à des tarifs conventionnés
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">
            Suivi transparent et détaillé des interactions et engagements
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">
            Offres adaptées selon les préférences de chaque collaborateur.
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">
            Gestion des conventions avec des marques de renom.
          </div>
        </Detail>
        {/* <BasicPaymentOptions color={theme.payementPlans.basic}>
          <div className="paymentPlanBox">
            <ContainedBox color={theme.payementPlans.basic}>
              {"< 250 employés*"}
            </ContainedBox>
            <p className="price">2.500</p>
            <p className="devise">MAD / an</p>
          </div>
          <div className="paymentPlanBox" style={{ marginBottom: "10px" }}>
            <div className="containedBoxWrapper">
              <ContainedBox
                style={{ marginBottom: "4px" }}
                color={theme.payementPlans.basic}
              >
                {"> 250 employés*"}
              </ContainedBox>
              <h6>
                *Sous réserve de l'activation d'au moins 20% de l'effectif.
              </h6>
            </div>
            <div className="freePaymentBox">
              <p className="price">0</p>
              <p className="devise">MAD</p>
            </div>
          </div>
        </BasicPaymentOptions> */}
      </PaymentDetails>
    </PayementOption>
  );
};

const StandardPlan = () => {
  const icon = (
    <img
      height={ICONHEIGHT}
      width={ICONWIDTH}
      src={CheckMarkGreen}
      color="red"
    />
  );
  const color = theme.payementPlans.standard;

  return (
    <PayementOption color={color}>
      <h1>Standard</h1>
      <PaymentDetails style={{ paddingTop: "0px" }} hoverShadowColor={color}>
        <ContainedBox
          style={{ marginTop: "13px", marginBottom: "-4px" }}
          color={color}
        >
          White Labelling
        </ContainedBox>
        <Detail>
          {icon}
          <div className="text">
            Un design personnalisable en accord avec l'identité visuelle de
            votre marque employeur.
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">
            Expérience utilisateur cohérente avec l'image de votre marque.
          </div>
        </Detail>
        {/* <BasicPaymentOptions color={color} style={{ marginTop: "0px" }}>
          <div className="paymentPlanBox" style={{ marginBottom: "8px" }}>
            <p className="price">15.000</p>
            <p className="devise">MAD / an</p>
          </div>
        </BasicPaymentOptions> */}
        <ContainedBox color={color}>G C C</ContainedBox>
        <Detail>
          {icon}
          <div className="text">
            Digitalisation et intégration des conventions externes à la
            plateforme eProgram.
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">
            Négociation et contractualisation avec les marques souhaitées ou
            partenaires.
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">
            Suivi trimestriel pour une performance optimale.
          </div>
        </Detail>
        {/* <BasicPaymentOptions color={color} style={{ marginTop: "0px" }}>
          <div className="paymentPlanBox" style={{ marginBottom: "8px" }}>
            <p className="price">13.000</p>
            <p className="devise">MAD / an</p>
          </div>
        </BasicPaymentOptions> */}
      </PaymentDetails>
    </PayementOption>
  );
};

const PremiumPlan = () => {
  const icon = (
    <img
      height={ICONHEIGHT}
      width={ICONWIDTH}
      src={CheckMarkGold}
      color="red"
    />
  );
  const color = theme.payementPlans.premium;

  return (
    <PayementOption color={color}>
      <h1>Premium</h1>
      <PaymentDetails hoverShadowColor={color}>
        <Detail>
          <div className="text" style={{ textAlign: "center" }}>
            Comprend les deux modules du Pack Standard à prix imbattable:
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">White Labelling.</div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">Gestion des Conventions Client.</div>
        </Detail>
        {/* <BasicPaymentOptions color={color} style={{ marginTop: "10px" }}>
          <div className="paymentPlanBox" style={{ marginBottom: "8px" }}>
            <p className="price">22.000</p>
            <p className="devise">MAD / an</p>
          </div>
        </BasicPaymentOptions> */}
      </PaymentDetails>
    </PayementOption>
  );
};

const RedPlan = () => {
  const icon = (
    <img height={ICONHEIGHT} width={ICONWIDTH} src={CheckMarkRed} color="red" />
  );
  const color = theme.payementPlans.red;

  return (
    <PayementOption color={color}>
      <h1>Sur-Mesure</h1>
      <PaymentDetails hoverShadowColor={color}>
        <Detail>
          {icon}
          <div className="text">
            Options d'accès alternatives au-delà du login conventionnel.
          </div>
        </Detail>
        <Detail>
          {icon}
          <div className="text">
            Intégration et configuration personnalisée de l'accès à eProgram.
          </div>
        </Detail>
        {/* <BasicPaymentOptions color={color} style={{ marginTop: "0px" }}>
          <div className="paymentPlanBox" style={{ marginBottom: "8px" }}>
            <p className="price">Sur Devis</p>
          </div>
        </BasicPaymentOptions> */}
      </PaymentDetails>
    </PayementOption>
  );
};

const ExtensionAnimatedBox = ({ title, substitle, details, footerContent }) => {
  
  const textMotion = {
    rest: {
      fontSize: '26px',
    },
    hover: {
      fontSize : '16px',
      lineHeight: '20px',
      transition: {
        duration: 0.8,
        type: "tween",
        ease: "easeOut"
      }
    }
  };

  const contentMotion = {
    rest: {
      height: '0px',
      display: 'none',
      width: '260px',
      transition: {
          duration: 0,
          type: "tween",
          ease: "easeIn"
        }
      },
      hover: {
        height: '295px',
        width: '260px',
        transition: {
          duration: 0.8,
          type: "tween",
          ease: "easeOut"
        },
        display: 'flex',
    }
  };

  
  const CardDetails = ({ text }) => {
    const img = <img src={ExtensionCheckMark} height={23} width={23} />
    return (
      <div>
        {img}
        <p>{text}</p>
      </div>
      )
  }

  const ExtentionDetailContent = (
    <ExtensionsDetailsBox variants={contentMotion}>
      <ExtentionDetailsSubTitle>{substitle}</ExtentionDetailsSubTitle>
      {details.map(detail => <CardDetails text={detail}/>)}
      <PriceWrraper color={theme.extensionPrimaryColor}>
        {footerContent}
      </PriceWrraper>
    </ExtensionsDetailsBox>
  )
  

  return (
    <div className="Box">
      <AnimatedContainer initial="rest" whileHover="hover" animate="rest">
        <motion.h1 variants={textMotion}>{title}</motion.h1>
        {ExtentionDetailContent}
      </AnimatedContainer>
    </div>
  )
}

const EXTENSIONS = [
  {
    titleElement : <>Kit de<br/>Communication</>,
    description : "Une gamme variée de templates génériques pour dynamiser vos communications internes sur les avantages et bénéfices.",
    details : [
      "Large choix de templates pour bannières, mailings, affichages, réseaux sociaux et plus.",
      "Facilement personnalisables pour s'adapter à l'identité visuelle de votre entreprise."
    ],
    footerContent : <><h3 style={{ paddingTop : "20px" }}>1.000</h3><h6 style={{ marginTop : '-2px' }}>MAD</h6></>
  },
  {
    titleElement : 'Engagement',
    description : "Programme d'engagement transformant les points basés sur des KPIs, en récompenses consommables sur la plateforme.",
    details : [
      "Intégration et maintenance d’une plateforme d'engagement sur mesure.",
      "Gestion des récompenses via un système de points dédié."
    ],
    footerContent : <h3 style={{ paddingTop : "62px" }}>Sur-Mesure</h3>
  },{
    titleElement : 'Sur-Mesure',
    description : "Contactez-nous pour explorer des solutions qui s'alignent précisément ave  les besoins spécifiques de votre entreprise.",
    details : [
      "Campagnes de communication internes sur mesure.",
      "Activations d'engagement et de récompense d'employés.",
      "Offres et récompenses personnalisées."
    ],
    footerContent : <h3 style={{ paddingTop : "27px" }}>Sur Devis</h3>
  }
]

const NewPricing = () => {
  const [viewportWidth, setViewportWidth] = useState(0);
  
  useEffect(() => {
    setViewportWidth(window.innerWidth);
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: viewportWidth < 560 ? 1 : viewportWidth > 900 ? 3 : 2,
    slidesToScroll: viewportWidth < 560 ? 1 : viewportWidth > 900 ? 3 : 2,
    // autoplay: true,
    // autoplaySpeed: 1000,
    pauseOnDotsHover: true
  };

  var settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: viewportWidth > 950 ? 3 : viewportWidth < 760 ? 1 : 2,
    slidesToScroll: viewportWidth > 950 ? 3 : viewportWidth < 760 ? 1 : 2,
    // autoplay: true,
    // autoplaySpeed: 2000,
    pauseOnDotsHover: true
  };

  const MobileDisplay = viewportWidth < 1200 && (
    <SliderWrapperStyle>
      <Slider {...settings}>
        <BasicPlan />
        <StandardPlan />
        <PremiumPlan />
        <RedPlan />
      </Slider>
    </SliderWrapperStyle>
  )

  const TablettDesktopDisplays = viewportWidth > 1200 && (
    <PayementOptionContainer>
      <BasicPlan />
      <StandardPlan />
      <PremiumPlan />
      <RedPlan />
    </PayementOptionContainer>
  )

  const AnimatedBoxSlider = viewportWidth < 1200 ? (
    <AnimatedBoxSliderWrapper>
      <Slider {...settings1}>
        {EXTENSIONS.map(item => <ExtensionAnimatedBox title={item.titleElement} substitle={item.description} details={item.details} footerContent={item.footerContent}/> )}
      </Slider>
    </AnimatedBoxSliderWrapper>
  ) : 
  <>
  {EXTENSIONS.map(item => <ExtensionAnimatedBox title={item.titleElement} substitle={item.description} details={item.details} footerContent={item.footerContent}/> )}
  </>

  return (
    <PricingExentsionWrapper>
        <PricingSectionWrapper>
          <h1 id="title">Pricing Plan</h1>
          <a>
            Une expérience <a>eProgram</a>
            <br />
            complète pour tous vos besoins.
          </a>
          {MobileDisplay}
          {TablettDesktopDisplays}
        </PricingSectionWrapper>
        <ExtensionSectionWrapper>
          <ExtensionsWrapper color={theme.extensionPrimaryColor}>
            <TextSection color={theme.extensionPrimaryColor}>
              <h2>Extensions</h2>
              <h2>disponibles</h2>
              <a>
                Optimisez votre expérience avec nos options supplémentaires,
                disponibles à la carte selon vos besoins.
              </a>
            </TextSection>
            {AnimatedBoxSlider}
          </ExtensionsWrapper>
          <a target="_blank" style={{zIndex: "10"}} href="https://calendly.com/ayoub-saaf/30min">
            <button className="footerButton">Prenez rendez-vous avec notre account manager</button>
          </a>
        </ExtensionSectionWrapper>
    </PricingExentsionWrapper>
  );
};
export default NewPricing;
